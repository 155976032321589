import React, { useRef, Fragment, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Formsy from "formsy-react";
import { useAsyncSetState } from "use-async-setstate";
import { useMutation, useQuery } from "@apollo/client";
import moment from "moment-mini";

import TextField from "../bootstrap/input";
import Modal from "../bootstrap/modal";
import {updateAccountMutation, createAccountMutation, getCurrentUserQuery} from "../logic/user";
import {getSiteInfoQuery, getSiteInfoResult, purchaseAndCreateUserMutation } from "../logic/purchase";
import {getOperationName} from "apollo-utilities";

export default function UpdateCC(props) {
  const [state, setState] = useAsyncSetState({
    processing: false,
  });

  const [error, setError] = useAsyncSetState(null);
  const [formValid, setFormValid] = useState(false);

  const { onClose, user } = props;
  const formRef = useRef(null);

  const accountId = (user?.accounts?.edges || [])[0]?.node?.id;
  const [mutation] = useMutation(accountId ? updateAccountMutation : createAccountMutation);

  const siteInfoQuery = useQuery(getSiteInfoQuery);
  if (siteInfoQuery.loading) {
    return (<Fragment/>);
  }
  const siteInfo = getSiteInfoResult(siteInfoQuery);
  const ewayCryptKey = siteInfo.auEwayClientKey;

  async function handleSubmit({ cardNumber, cardName, cardCCV, expiryMonth, expiryYear }) {
    try {
      let expiry = moment(
        `${expiryMonth}${expiryYear}`,
        "MMYY"
      );
      const isValid = expiry.isValid() && moment(expiry).isSameOrAfter(moment().startOf("month"));

      if (!isValid) {
        return setState({...state, error: "Invalid Card Expiry"});
      }

      await setState((prevState) => ({ ...prevState, processing: true, error: "" }));
      const result = await mutation({
        variables: {
          accountId,
          userId: user?.id,
          name: `${user?.firstName || ""} ${user?.lastName || ""}`,
          ccard: {
            card: window.eCrypt.encryptValue(cardNumber, ewayCryptKey),
            cvn: window.eCrypt.encryptValue(cardCCV, ewayCryptKey),
            name: cardName,
            month: expiryMonth,
            year: expiryYear,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [getOperationName(getCurrentUserQuery)],
      });

      const errorMessage = (result?.errors || [])[0]?.message;
      if (errorMessage) {
        await setError(errorMessage || "Something went wrong");
        return setState((prevState) => ({ ...prevState, processing: false }));
      }
      await setState((prevState) => ({ ...prevState, processing: false }));
      return onClose();
    } catch (err) {
      await setError((err?.meta?.graphQLErrors || [])[0]?.message || err.message || "Something went wrong");
      return setState((prevState) => ({ ...prevState, processing: false }));
    }
  }

  return (
    <Modal
      title={"Update Credit Card"}
      show
      onClose={async () => onClose()}
      footer={
        <Container>
          <Row>
            <Col xs="auto" className="ml-auto">
              <Button
                type="button"
                variant="black-text"
                disabled={state.processing}
                onClick={async () => {
                  return onClose();
                }}
              >
                {"Cancel"}
              </Button>
            </Col>
            <Col xs="auto">
              <Button
                variant="darkblue"
                type="submit"
                disabled={state.processing || !formValid}
                onClick={() => formRef.current.submit()}
              >
                {"Save"}
              </Button>
            </Col>
          </Row>
        </Container>
      }
    >
      <Formsy
        ref={formRef}
        onValidSubmit={async (data) => handleSubmit(data)}
        onInvalid={() => setFormValid(false)}
        onValid={() => setFormValid(true)}
      >
        {error && (
          <Row>
            <Col>
              <div className="alert alert-danger">{error}</div>
            </Col>
          </Row>
        )}
        <Row className="field-row username-field mb-2">
          <Col>
            <Row className="field-row mb-2">
              <Col>
                <TextField
                  inline
                  label={"Card Number"}
                  name="cardNumber"
                  placeholder={"Card Number"}
                  validations={{
                    maxLength: 16,
                    minLength: 16,
                    isNumeric: true,
                    isExisty: true,
                  }}
                  validationErrors={{
                    isNumeric: "You can only enter in numerical characters",
                    isExisty: "This field is required",
                    maxLength: "You can not type in more than 16 characters",
                    minLength: "You can not type in less than 16 characters",
                  }}
                  required
                />
              </Col>
            </Row>
            <Row className="field-row mb-2">
              <Col>
                <TextField
                  inline
                  label={"Card Name"}
                  placeholder={"Card Name"}
                  name="cardName"
                  disabled={state.processing}
                  required
                  validations={{
                    maxLength: 50,
                    isExisty: true,
                    matchRegexp: /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/,
                  }}
                  validationErrors={{
                    isExisty: "This field is required",
                    maxLength: "You can not type in more than 50 characters",
                    matchRegexp: "Enter the name on your credit card",
                  }}
                />
              </Col>
            </Row>
            <Row className="field-row mb-2">
              <Col>
                <TextField
                  inline
                  label={"CCV"}
                  name="cardCCV"
                  placeholder={"CCV"}
                  validations={{
                    minLength: 3,
                    maxLength: 3,
                    isExisty: true,
                    isNumeric: true,
                  }}
                  validationErrors={{
                    isNumeric: "You can only enter in numerical characters",
                    isExisty: "CCV field is required",
                    minLength: "You can not type in less than 3 characters",
                    maxLength: "You can not type in more than 2 characters",
                  }}
                  disabled={state.processing}
                  required
                />
              </Col>
            </Row>
            <Row className="field-row no-gutters mb-2">
              <Col style={{ paddingTop: 5 }} xs={3}>
                <label>{"Card Expiry"}</label>
              </Col>
              <Col style={{ marginLeft: 8 }} xs={3}>
                <TextField
                  inline
                  name="expiryMonth"
                  placeholder={"Month (MM)"}
                  validations={{
                    maxLength: 2,
                    minLength: 2,
                    isNumeric: true,
                    isExisty: true,
                  }}
                  validationErrors={{
                    minLength: "You can not type in less than 2 characters",
                    maxLength: "You can not type in more than 2 characters",
                    isNumeric: "You can only enter in numerical characters",
                    isExisty: "This field is required",
                  }}
                  disabled={state.processing}
                  required
                />
              </Col>
              <Col
                style={{ marginLeft: 8, marginRight: 8 }}
                className="align-self-center"
                xs="auto"
              >
                <span style={{ fontSize: 30 }}>{"/"}</span>
              </Col>
              <Col xs={3}>
                <TextField
                  inline
                  name="expiryYear"
                  placeholder={"Year (YY)"}
                  validations={{
                    maxLength: 2,
                    minLength: 2,
                    isNumeric: true,
                    isExisty: true,
                    isValidYear: (values) => {
                      if (values.expiryMonth?.trim !== "" && values.expiryYear?.trim() !== "") {
                        const today = moment().startOf("month")
                        const inputtedDate = moment(`${values.expiryMonth}/01/${values.expiryYear}`).startOf("month")

                        if (inputtedDate <= today) {
                          return "Card already expired"
                        }
                      }

                      return true
                    }
                  }}
                  validationErrors={{
                    minLength: "You can not type in less than 2 characters",
                    maxLength: "You can not type in more than 2 characters",
                    isNumeric: "You can only enter in numerical characters",
                    isExisty: "This field is required",
                  }}
                  disabled={state.processing}
                  required
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Formsy>
    </Modal>
  );
}
